import {
  FETCH_PAY_PERIODS_START,
  FETCH_PAY_PERIODS_ERROR,
  FETCH_PAY_PERIODS_SUCCESS,
  FETCH_PAY_PERIOD_SUCCESS,
  CLEAR_PAY_PERIODS,
  RECEIVE_PAY_PERIOD_IS_APPROVING,
} from "../actions/shared/payPeriodActions";

const initialState = {
  loading: false,
  items: {},
  error: null,
  isApproving: false,
};

export default function payPeriodReducer(
  state = initialState,
  { type, payload } = {}
) {
  switch (type) {
    case FETCH_PAY_PERIODS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PAY_PERIODS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case FETCH_PAY_PERIODS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: { ...payload },
      };
    case FETCH_PAY_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        items: { ...state.items, ...payload },
      };
    case RECEIVE_PAY_PERIOD_IS_APPROVING:
      return {
        ...state,
        isApproving: payload,
      };
    case CLEAR_PAY_PERIODS:
      return initialState;
    default:
      return state;
  }
}
