import get from "lodash/get";
import React from "react";
import { connect } from "react-redux";

import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import { getCurrentOrganization } from "../../../../selectors/organizationSelectors";
import Earnings from "../../../recruiter/Earnings";
import OmnisearchButton from "../../../shared/Omnisearch/OmnisearchButton";

import CommunityCoinsBalance from "./CommunityCoinsBalance";
import UserSectionAlerts from "./UserSectionAlerts";

import UserSectionMenu from "./UserSectionMenu";

function ActivatedRecruiter({ currentIndividual }) {
  const isTalkOnly =
    currentIndividual?.agreedToTalkTerms &&
    !currentIndividual?.agreedToRfiTerms;

  return (
    <>
      {!isTalkOnly && <OmnisearchButton {...{ currentIndividual }} />}
      {!isTalkOnly && (
        <>
          <UserSectionAlerts />
          <CommunityCoinsBalance />
        </>
      )}
      <Earnings />
    </>
  );
}

function ActivatedEmployer({ currentIndividual }) {
  return (
    <>
      <OmnisearchButton {...{ currentIndividual }} />
      <UserSectionAlerts />
    </>
  );
}

class UserSection extends React.Component {
  render() {
    const { loaded, currentIndividual, activated } = this.props;
    const recruiter = get(currentIndividual, "recruiter");

    if (loaded) {
      return (
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          { activated && recruiter && <ActivatedRecruiter {...{ currentIndividual }} />}
          { activated && !recruiter && <ActivatedEmployer {...{ currentIndividual }} />}
          <UserSectionMenu
            data-cy="profile-setting"
            individual={currentIndividual}
            activated={activated}
          />
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  const currentIndividual = getCurrentIndividual(state);
  const currentOrganization = getCurrentOrganization(state);

  const activated = Boolean(
    get(currentIndividual, "activatedAt") &&
      get(currentOrganization, "activatedAt")
  );

  return {
    currentIndividual,
    loaded: Boolean(currentIndividual),
    activated,
  };
};

const mapDispatchToProps = (_dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserSection);
