import normalize from "json-api-normalizer";
import omit from "lodash/omit";
import queryString from "query-string";
import { batch } from "react-redux";

import { GET, PATCH } from "../../util/apiHelpers";

import { receiveCandidates } from "../shared/candidateActions";
import { fetchContractSuccess } from "../shared/contractActions";
import { receiveJobcasts } from "../shared/jobcastActions";
import {
  fetchPayPeriodsStart,
  fetchPayPeriodSuccess,
  fetchPayPeriodsError,
  receivePayPeriodIsApproving,
} from "../shared/payPeriodActions";
import { refetchLeftDrawerCounts } from "../shared/uiActions";

export function fetchPayPeriods(params = {}) {
  const { candidateId } = params;

  const baseUrl = candidateId
    ? `/api/v3/recruiter/candidates/${candidateId}/pay_periods`
    : "/api/v3/recruiter/pay_periods";

  const queryStr = queryString.stringify(omit(params, ["candidateId"]));

  const url = queryStr ? `${baseUrl}?${queryStr}` : baseUrl;

  return (dispatch) => {
    dispatch(fetchPayPeriodsStart());

    return GET(url)
      .then((response) => {
        const normalized = normalize(response.data);

        return batch(() => {
          dispatch(receiveCandidates(normalized.candidate));
          dispatch(receiveJobcasts(normalized.jobcast));
          dispatch(fetchPayPeriodSuccess(normalized.payPeriod));
          dispatch(fetchContractSuccess(normalized.contract));
        });
      })
      .catch((error) => dispatch(fetchPayPeriodsError(error)));
  };
}

export function updatePayPeriod(payPeriodId, values) {
  const url = `/api/v3/recruiter/pay_periods/${payPeriodId}`;

  return (dispatch) => PATCH(url, values).then((response) => {
    const normalized = normalize(response.data);
    dispatch(fetchPayPeriodSuccess(normalized.payPeriod));
    dispatch(refetchLeftDrawerCounts());
  });
}

export function approvePayPeriod(payPeriodId, edited_at) {
  const url = `/api/v3/recruiter/pay_periods/${payPeriodId}/approve`;

  return (dispatch) => {
    dispatch(receivePayPeriodIsApproving(true));

    return PATCH(url, { edited_at }).then((response) => {
      const normalized = normalize(response.data);
      dispatch(fetchPayPeriodSuccess(normalized.payPeriod));
      dispatch(refetchLeftDrawerCounts());
      dispatch(receivePayPeriodIsApproving(false));
    });
  };
}

export function disputePayPeriod(payPeriodId, dispute_explanation, edited_at) {
  const url = `/api/v3/recruiter/pay_periods/${payPeriodId}/dispute`;

  return (dispatch) =>
    PATCH(url, { dispute_explanation, edited_at }).then((response) => {
      const normalized = normalize(response.data);
      dispatch(fetchPayPeriodSuccess(normalized.payPeriod));
      dispatch(refetchLeftDrawerCounts());
    });
}
