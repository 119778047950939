import React from 'react';

import RotatingEmptyState from "../../EmptyState/RotatingEmptyState";

class ResumeTab extends React.PureComponent {
  render() {
    const { resume, offPlatform } = this.props.candidate.attributes;

    if (!resume) {
      return <RotatingEmptyState />;
    }

    const isDocFile = new URL(resume).pathname.toLowerCase().endsWith(".doc") || new URL(resume).pathname.toLowerCase().endsWith(".docx");

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        {offPlatform || isDocFile ? (
          <RotatingEmptyState />
        ) : (
          <iframe
            title="resume"
            src={resume}
            style={{
              padding: "12px",
              backgroundColor: "#F5F5F5",
              height: "100%",
              width: "100%",
              border: "none",
              overflow: "auto",
            }}
          />
        )}
      </div>
    );
  }
}

export default ResumeTab;
