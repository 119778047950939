export const FETCH_PAY_PERIODS_START = "FETCH_PAY_PERIOD_START";
export const FETCH_PAY_PERIODS_ERROR = "FETCH_PAY_PERIOD_ERROR";
export const FETCH_PAY_PERIODS_SUCCESS = "FETCH_PAY_PERIODS_SUCCESS";
export const FETCH_PAY_PERIOD_SUCCESS = "FETCH_PAY_PERIOD_SUCCESS";
export const CLEAR_PAY_PERIODS = "CLEAR_PAY_PERIODS";
export const RECEIVE_PAY_PERIOD_IS_APPROVING = "RECEIVE_PAY_PERIOD_IS_APPROVING";

export const receivePayPeriodIsApproving = (isApproving) => ({
  type: RECEIVE_PAY_PERIOD_IS_APPROVING,
  payload: isApproving,
});

export const fetchPayPeriodsStart = () => ({
  type: FETCH_PAY_PERIODS_START,
});

export const fetchPayPeriodsError = (error) => ({
  type: FETCH_PAY_PERIODS_ERROR,
  payload: error,
});

export const fetchPayPeriodSuccess = (payPeriod) => ({
  type: FETCH_PAY_PERIOD_SUCCESS,
  payload: payPeriod,
});

export const fetchPayPeriodsSuccess = (payPeriods) => ({
  type: FETCH_PAY_PERIODS_SUCCESS,
  payload: payPeriods,
});

export const clearPayPeriods = () => ({
  type: CLEAR_PAY_PERIODS,
});
